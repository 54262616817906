/* check longpurplebtn compinent* for purple-btn*/


.purple-outline-btn{ 
    background-color:#202024 ;
    border: #8E69F0 1px solid;
}
.nxt-btn{
    background-color: #8E69F0;
    width: 65%;
    height: 40px;
}
/*check DangerBtn*/

/*check LongLilacBtn*/

