.container{
    
    margin-top: 20px;
    height: inherit;
    overflow-y: scroll;
    width: 100%;
    align-items: center;
    color: #f0f0f0;
    display: flex;
    flex-direction: column;
    position:"relative";
    padding-bottom: 20px;
  }

  .island{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /*check input component for .island input*/

  .island-top,.island-bottom,.island-title,.island-para{
    position: absolute;
    top:10px;
    z-index:10;
  }
  .island-para{
    top:130px;
    padding: 0 40px;
  }
  .island-title{
  
    top:150px;
  
  }
  .island-top{
    top: 80px;
    display: flex;
    flex-direction: column;
  }
  .island-bottom{
    top: auto;
    bottom: 70px;
  }

  .opacity {
    opacity: 0.2
  }