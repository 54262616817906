.circular-div {
    width: 15px;
    height: 15px;
    background-color: #8e69f0;
    border-radius: 50%;
    animation: blink-animation 0.5s infinite alternate;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.7;
    }
}
