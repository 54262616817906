* {
    user-select: none;
  }
#app{
    background-color: #202024;
    height: 100vh;
    width: 100vw;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    padding: 0 20px;
  
  
}