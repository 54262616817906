.event-card-title{
    color: #FFF;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
 
}

.po{
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}




.inter-400, .inter-light{
    color: #FFFFFF;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.inter-10px-400{
    font-family: 'Inter';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.inter-purple{
    color: #8E69F0;
}
.poppings-normal{
    color: #FFF;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    line-height: normal;
}

.poppins-17px-300{

font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.poppins-10px-200{

    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight:2300;
    line-height: normal;
    }
.inter-light{
    color: #FFF;    
    font-size: 13px;
    font-weight: 300;
}


.page-title{
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.btn-text, .poppins-15px-500{
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.role-info{
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.popping-300-normal{
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}



/* .swipecard-detail-1 p, .swipecard-detail-2 p, */
 .poppins-10px-300{
 
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 300;
line-height: normal;
}

.poppins-black-small{
    color: #616263;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
}




.poppins-200px-600 {
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.poppins-12px-400{

font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.poppins-12px-300{

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    
    }

.poppins-15px-400{
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}

.poppins-15px-200{
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
   
    
}

.poppins-10px-275{
    color: #FFF;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 275;
line-height: normal;
}

.poppins-10px-400{
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.poppins-10px-300{
 
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 300;
line-height: normal;
}

/* poppins-heavy,page-sub-title*/
.poppins-20px-500{
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/*black-medium white-medium*/
.poppins-14px-400{
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}